import React, { useState } from 'react';
import BarcodeScanner from '../components/ScannerWeb';
import { useIndexedDB } from 'react-indexed-db-hook';
import Modal from '../components/Modal';
import { toast } from 'react-toastify';

const SanitaryLableQRCode = () => {
  const { getAll } = useIndexedDB('sanitary');
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [selectedLot, setSelectedLot] = useState(null);

  const handleSanitary = async (id) => {
    const allAssets = await getAll();
    const response = allAssets.filter((item) => item.Lot_Vendor === id);

    console.log('Response : ', response);
    if (response.length === 0) {
      toast.error('Record does not exist.');
      return;
    }

    if (response?.length > 1) {
      setData(response);
      setOpen(() => true);
    }
  };

  const handleClose = () => {
    setOpen(() => false);
  };

  const handleSelect = (e, item) => {
    console.log(item);
    setSelectedLot(item);
  };

  return (
    <div className='mt-[10rem] w-full flex flex-col gap-4 justify-center items-center'>
      <BarcodeScanner handleData={handleSanitary} placeholder={'Scan or Manual Input Lot ID'} />
      <Modal open={open} handleClose={handleClose}>
        <div className='relative overflow-x-auto shadow-md sm:rounded-lg'>
          <table className='w-full text-sm text-left rtl:text-right text-gray-500 '>
            <thead className='text-xs text-gray-700 uppercase bg-gray-50 '>
              <tr>
                <th scope='col' className='px-4 py-3'>
                  Lot No
                </th>
                <th scope='col' className='px-4 py-3'>
                  Item No
                </th>

                <th scope='col' className='p-4'>
                  Select Item
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr className='bg-white border-b  hover:bg-gray-50 ' key={index} onClick={(e) => handleSelect(e, item)}>
                  <th className='px-4 py-4'>{item.Lot_Vendor}</th>
                  <td className='px-4 py-4'>{item.Item_No}</td>
                  <td className='w-4 p-4'>
                    <div className='flex items-center'>
                      <input
                        type='checkbox'
                        checked={item.Item_No === selectedLot?.Item_No}
                        onChange={(e) => handleSelect(e, item)}
                        className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 '
                      />
                      <label className='sr-only'>checkbox</label>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default SanitaryLableQRCode;
