import { useState } from 'react';
import { IoMdArrowRoundForward } from 'react-icons/io';
import useUserStore from '../app/user';
import axios from '../config/axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaArrowDown } from 'react-icons/fa';
import useOnline from '../hooks/useOnline';

const Login = () => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const { setUser, site } = useUserStore();
  const { isOnline } = useOnline();

  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    if (!isOnline) {
      toast.error('You are offline');
      return;
    }

    const request = window.indexedDB.open('Print_Sales', 2);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      // Create a new object store called "customers"
      if (!db.objectStoreNames.contains('customers')) db.createObjectStore('customers', { keyPath: 'Id' });

      if (!db.objectStoreNames.contains('items')) db.createObjectStore('items', { keyPath: 'Id' });

      if (!db.objectStoreNames.contains('sanitary')) db.createObjectStore('sanitary', { keyPath: 'Id' });

      if (!db.objectStoreNames.contains('items_translate')) db.createObjectStore('items_translate', { keyPath: 'Id' });

      // Create an index on the "name" property of the object store
      console.log('Database created and object store created.');
    };

    axios
      .get('/Login', { auth: { username, password } })
      .then(({ data }) => {
        console.log('Data ---- ', data);
        navigate('/');
        setUser({ username, password });
        toast.success('Login Successfully');
      })
      .catch((err) => {
        console.log('Error ---- ', err.response.statusText);
        toast.error(err.response.statusText);
      });
  };

  const handleSiteUrl = () => {
    navigate('/site-url');
  };

  return (
    <div className=' w-screen flex flex-col  p-3 z-50 absolute bg-white'>
      {/* top images */}
      <div className='imgs w-full flex justify-between items-center'>
        <img src='/images/logo.png' className='w-[12rem] h-20 object-cover ' alt='B tech name company' />
        <img src='/images/initalt-pic2.jpg' className='w-[8rem]' alt='initial-pic' />
      </div>

      <div className='bottom-sectino mt-20 flex flex-col gap-7 px-2'>
        {/* dummy url */}
        <div className='text-balance font-semibold flex items-center text-lg gap-1'>
          {site?.url ? site?.url : 'https://erp.initaly.al'}
          <button onClick={handleSiteUrl}>
            <FaArrowDown />
          </button>
        </div>
        {/* input-fields */}
        <form action='' className=' flex flex-col gap-7  w-[90%]'>
          <input
            type='text'
            name='username'
            placeholder='username'
            onChange={(e) => setUsername(e.target.value)}
            className='p-2 border border-gray-800 rounded-sm'
          />
          <div className='psdwrapper flex gap-2 items-center'>
            <input
              type='password'
              name='passowrd'
              placeholder='password'
              className='p-2 w-[90%] border border-gray-800 rounded-sm'
              onChange={(e) => setPassword(e.target.value)}
            />
            {/* icons */}
            <button className='text-[2rem]' onClick={handleLogin}>
              <IoMdArrowRoundForward />
            </button>
          </div>
        </form>
        {/* <label htmlFor='checkbox' className='flex gap-2'>
          <input type='checkbox' className='text-[1.3rem]' name='eheckbox' id='checkbox' />
          <p className='font-semibold text-black text-[1.2rem]'> Remember me</p>
        </label> */}
      </div>
    </div>
  );
};

export default Login;
