import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useUserStore = create()(
  persist(
    (set) => ({
      user: null,
      site: { url: '', name: '' },

      setUser: (user) => set(() => ({ user })),

      clearUser: () => set(() => ({ user: null })),

      setSiteUrl: (url) => set((state) => ({ site: { ...state.site, url } })),

      setSiteName: (name) => set((state) => ({ site: { ...state.site, name } })),
    }),
    {
      name: 'print-label',
    }
  )
);

export default useUserStore;
