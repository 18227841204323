import { IoHomeSharp } from 'react-icons/io5';
import { CiWifiOff, CiWifiOn } from 'react-icons/ci';
import { HiOutlineRefresh } from 'react-icons/hi';
import { CiMenuKebab } from 'react-icons/ci';
import { useEffect, useState } from 'react';
import LogoutAskModal from './LogoutModal';
import useOnline from '../hooks/useOnline';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { pageTitles } from '../utils/page.title';
import { getCustomers, getItems, getItemsTranslate, getSanitary } from '../config/api';
import { initDB, useIndexedDB } from 'react-indexed-db-hook';
import { DBConfig } from '../config/db';
import { insertDataInDB } from '../utils/insertdata';
import { toast } from 'react-toastify';

initDB(DBConfig);

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isOnline } = useOnline();

  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState('Main Page');

  const { add, clear } = useIndexedDB('customers');
  const { add: addItems, clear: clearItems } = useIndexedDB('items');
  const { add: addItemsTranslate, clear: clearItemsTranslate } = useIndexedDB('items_translate');
  const { add: addSanitary, clear: clearSanitary } = useIndexedDB('sanitary');

  const { refetch: refetchCustomers } = useQuery({
    queryKey: ['customers'],
    queryFn: getCustomers,
    staleTime: 1000 * 60 * 60,
  });

  const { refetch: refetchItems } = useQuery({
    queryKey: ['items'],
    queryFn: getItems,
    staleTime: 1000 * 60 * 60,
  });

  const { refetch: refetchItemsTranlsate } = useQuery({
    queryKey: ['items-translate'],
    queryFn: getItemsTranslate,
    staleTime: 1000 * 60 * 60,
  });

  const { refetch: refchSanitary } = useQuery({
    queryKey: ['sanitary'],
    queryFn: getSanitary,
    staleTime: 1000 * 60 * 60,
  });

  const handleLogoutModal = () => {
    setShowModal((prev) => !prev);
  };
  // to refresh the page
  const refreshPage = async () => {
    if (!isOnline) {
      toast.error('You are offline');
      return;
    }

    refetchCustomers().then(({ data }) => {
      insertDataInDB(clear, add, data);
    });
    refetchItems().then(({ data }) => {
      insertDataInDB(clearItems, addItems, data);
    });
    refetchItemsTranlsate().then(({ data }) => {
      insertDataInDB(clearItemsTranslate, addItemsTranslate, data);
    });
    refchSanitary().then(({ data }) => {
      insertDataInDB(clearSanitary, addSanitary, data);
    });
  };

  useEffect(() => {
    const pathname = location.pathname;

    const page = pageTitles.find((item) => item.path === pathname);

    setTitle(page?.name);
  }, [location?.pathname]);

  const handleHome = () => {
    navigate('/');
  };

  return (
    <>
      <div className=' w-screen fixed top-0 z-30 bg-gray-300 px-3 py-5 flex justify-between'>
        {/* left side */}
        <div className='leftSide w-[45%] flex gap-4 items-center'>
          <h5 className='text-[1rem] font-semibold'>{title}</h5>
        </div>

        {/* right side */}

        <div className='rightSide w-[50%] flex gap-3 justify-end items-center'>
          <button onClick={handleHome} className='menuIcon text-[1.6rem]'>
            <IoHomeSharp />
          </button>
          <div className={`menuIcon font-bold text-[2rem] ${isOnline ? 'text-green-800' : 'text-gray-500'}`}>
            {isOnline ? <CiWifiOn className='text-green-500' /> : <CiWifiOff />}
          </div>
          <div className='menuIcon text-[1.6rem]' onClick={refreshPage}>
            <HiOutlineRefresh />
          </div>
          <div className='menuIcon text-[1.6rem]' onClick={handleLogoutModal}>
            <CiMenuKebab />
          </div>
        </div>
      </div>
      {/* logout out modal */}
      {showModal && <LogoutAskModal setShowModal={setShowModal} />}
    </>
  );
};

export default Navbar;
