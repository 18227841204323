import { useIndexedDB } from 'react-indexed-db-hook';
import BarcodeScanner from '../components/ScannerWeb';
import { toast } from 'react-toastify';

const ArtikulletQRCode = () => {
  const { getAll } = useIndexedDB('items_translate');

  const handleItem = async (id) => {
    const allAssets = await getAll();
    const response = allAssets.filter((item) => item.Item_No === id);

    console.log('Response : ', response);

    if (response.length === 0) {
      toast.error('Record does not exist.');
      return;
    }
  };
  return (
    <div className='mt-[10rem] w-full flex flex-col gap-4 justify-center items-center'>
      <BarcodeScanner handleData={handleItem} placeholder={'Scan or Manual Input Item ID'} />
    </div>
  );
};

export default ArtikulletQRCode;
