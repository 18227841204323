import React from "react";
import { IoMdArrowRoundForward } from "react-icons/io";

const NewLotLabelQRCode = () => {
  return (
    <div className="mt-[10rem] w-full flex flex-col gap-4 justify-center items-center">
      {/* camera opening and scanning QR Code */}
      <img src="/images/scan-qr-code.jpeg" alt="scan-code" />

      {/* input field */}
      <div className=" w-[70%] flex gap-3 mt-[4rem] justify-center items-center">
        <input
          type="password"
          name="passowrd"
          className="p-2 w-[95%] border border-gray-800 rounded-sm"
          id=""
          placeholder="Scan or Manual Input Item ID"
        />
        {/* icons */}
        <p className="text-[2.5rem]">
          <IoMdArrowRoundForward />
        </p>
      </div>
    </div>
  );
};

export default NewLotLabelQRCode;
