import { ToastContainer } from 'react-toastify';
import './App.css';
import Router from './routes';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className='w-full'>
      <Router />
      <ToastContainer />
    </div>
  );
}

export default App;
