import React from 'react';
import BarcodeScanner from '../components/ScannerWeb';
import { useIndexedDB } from 'react-indexed-db-hook';
import { toast } from 'react-toastify';

const KlientitQRCode = () => {
  const { getAll } = useIndexedDB('customers');

  const handleCustomer = async (id) => {
    const allAssets = await getAll();
    const response = allAssets.filter((item) => item.No === id);

    console.log('Response : ', response);

    if (response.length === 0) {
      toast.error('Record does not exist.');
      return;
    }
  };
  return (
    <div className='mt-[10rem] w-full flex flex-col gap-4 justify-center items-center'>
      <BarcodeScanner handleData={handleCustomer} placeholder={'Scan or Manual Input Customer ID'} />
    </div>
  );
};

export default KlientitQRCode;
