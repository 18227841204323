import React, { useState } from 'react';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { BsUpcScan } from 'react-icons/bs';
// import { Scanner } from '@yudiel/react-qr-scanner';
import Modal from '../Modal';
import { IoMdArrowRoundForward } from 'react-icons/io';

const BarcodeScanner = ({ placeholder, handleData }) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');

  const handleBarcodeScan = (err, result) => {
    if (result) {
      setText(() => result.text);
      handleClose();
    }
  };

  const handleError = (err) => {
    console.log('Error in Scan : ', err);
  };

  const handleScanOpen = () => {
    setOpen(() => true);
  };

  const handleClose = () => {
    setOpen(() => false);
  };

  const handleClick = () => {
    if (text) handleData(text);
  };

  return (
    <div className='flex flex-col w-full justify-center items-center'>
      <button onClick={handleScanOpen} className='h-32 w-32'>
        <BsUpcScan className='w-full h-full' />
      </button>
      <div className=' w-full flex gap-3 mt-[4rem] justify-center items-center'>
        <input
          value={text}
          onChange={(e) => setText(e.target.value)}
          className='p-2 w-4/5 border border-gray-800 rounded-sm'
          placeholder={placeholder}
        />
        <button className='text-[2.5rem]' onClick={handleClick}>
          <IoMdArrowRoundForward />
        </button>
      </div>
      {open && (
        <Modal open={open} handleClose={handleClose}>
          <BarcodeScannerComponent width={500} height={500} onUpdate={handleBarcodeScan} onError={handleError} />
        </Modal>
      )}
    </div>
  );
};

export default BarcodeScanner;
