import { Link } from 'react-router-dom';
import axios from '../config/axios';
import { useIndexedDB } from 'react-indexed-db-hook';
import { getCustomers, getItems, getItemsTranslate, getSanitary } from '../config/api';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { insertDataInDB } from '../utils/insertdata';
import useUserStore from '../app/user';
import { toast } from 'react-toastify';
import useOnline from '../hooks/useOnline';

const HomePage = () => {
  const { add, clear } = useIndexedDB('customers');
  const { add: addItems, clear: clearItems } = useIndexedDB('items');
  const { add: addItemsTranslate, clear: clearItemsTranslate } = useIndexedDB('items_translate');
  const { add: addSanitary, clear: clearSanitary } = useIndexedDB('sanitary');
  const { site } = useUserStore();
  const { isOnline } = useOnline();

  const { data: customers, status: isCustomerLoaded } = useQuery({
    queryKey: ['customers'],
    queryFn: getCustomers,
    staleTime: 1000 * 60 * 60,
  });

  const { data: items, status: isItemLoaded } = useQuery({
    queryKey: ['items'],
    queryFn: getItems,
    staleTime: 1000 * 60 * 60,
  });

  const { data: itemstranslate, status: isItemTranslateLoaded } = useQuery({
    queryKey: ['items-translate'],
    queryFn: getItemsTranslate,
    staleTime: 1000 * 60 * 60,
  });

  const { data: sanitary, status: isSanitaryLoaded } = useQuery({
    queryKey: ['sanitary'],
    queryFn: getSanitary,
    staleTime: 1000 * 60 * 60,
  });

  const BTNS_LINKS = [
    {
      name: 'Etiketa Klientit (Customer Info Lablel)',
      LinkTo: '/customer-info-label',
    },
    {
      name: 'Etiketa Artikullit (Items Translate Lablel)',
      LinkTo: '/items-translate-label',
    },
    {
      name: 'Etiketa Sanitare (Sanitary Label',
      LinkTo: '/sanitary-label',
    },
    {
      name: 'Kopjo Lot (Copy Lot Label)',
      LinkTo: '/copy-lot-label',
    },
    // {
    //   name: 'Gjenero Lot (New Lot Label)',
    //   LinkTo: '/new-lot-label',
    // },
  ];

  useEffect(() => {
    if (isCustomerLoaded === 'success') {
      insertDataInDB(clear, add, customers);
    }
  }, [isCustomerLoaded, customers, add, clear]);

  useEffect(() => {
    if (isItemLoaded === 'success') {
      insertDataInDB(clearItems, addItems, items);
    }
  }, [isItemLoaded, items, addItems, clearItems]);

  useEffect(() => {
    if (isItemTranslateLoaded === 'success') {
      insertDataInDB(clearItemsTranslate, addItemsTranslate, itemstranslate);
    }
  }, [isItemTranslateLoaded, itemstranslate, addItemsTranslate, clearItemsTranslate]);

  useEffect(() => {
    if (isSanitaryLoaded === 'success') {
      insertDataInDB(clearSanitary, addSanitary, sanitary);
    }
  }, [isSanitaryLoaded, sanitary, addSanitary, clearSanitary]);

  const handleGenerateLot = () => {
    if (!isOnline) {
      toast.error('You are offline');
      return;
    }

    const company = site?.name ? site?.name : 'InItalyProd';

    axios
      .post(`/AutomaticLotNo_GenerateWebServiceLotNo?company=${company}`)
      .then(({ data }) => {
        console.log('Data --- ', data);
      })
      .catch((err) => {
        console.log('Error --- ', err);
      });
  };

  return (
    <div className=' w-screen mt-[6rem] flex flex-col gap-4 p-3'>
      {BTNS_LINKS.map((btn, index) => (
        <Link
          to={btn.LinkTo}
          id={index}
          key={index}
          className='py-8 rounded-md p-1 text-[1rem] hover:bg-gray-200 border border-gray-500'
        >
          {btn?.name} {/* Use btn directly instead of btn[index] */}
        </Link>
      ))}
      <button
        onClick={handleGenerateLot}
        className='py-8 rounded-md p-1 text-[1rem] text-left hover:bg-gray-200 border border-gray-500'
      >
        Gjenero Lot (New Lot Label)
      </button>
    </div>
  );
};

export default HomePage;
