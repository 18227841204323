export const DBConfig = {
  name: 'Print_Sales',
  version: 2,
  objectStoresMeta: [
    {
      store: 'customers',
      storeConfig: { keyPath: 'Id' },
      storeSchema: [],
    },
    {
      store: 'items',
      storeConfig: { keyPath: 'Id' },
      storeSchema: [],
    },
    {
      store: 'sanitary',
      storeConfig: { keyPath: 'Id' },
      storeSchema: [],
    },
    {
      store: 'items_translate',
      storeConfig: { keyPath: 'Id' },
      storeSchema: [],
    },
  ],
};
