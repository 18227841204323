import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from '../pages/Login';
import HomePage from '../pages/HomePage';
import NotFound from '../pages/NotFound';
import KlientitQRCode from '../pages/KlientitQRCode';
import ArtikulletQRCode from '../pages/ArtikulletQRCode';
import SanitaryLableQRCode from '../pages/SanitaryLabel';
import CopyLotLableQRCode from '../pages/CopyLotLableQRCode';
import NewLotLabelQRCode from '../pages/NewLotLabelQRCode';
import SiteUrl from '../pages/SiteUrl';
import AuthLayout from '../layout/AuthLayout';

const Router = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/site-url' element={<SiteUrl />} />
          <Route element={<AuthLayout />}>
            <Route path='/' element={<HomePage />} />
            <Route path='/customer-info-label' element={<KlientitQRCode />} />
            <Route path='/items-translate-label' element={<ArtikulletQRCode />} />
            <Route path='/sanitary-label' element={<SanitaryLableQRCode />} />
            <Route path='/copy-lot-label' element={<CopyLotLableQRCode />} />
            <Route path='/new-lot-label' element={<NewLotLabelQRCode />} />
          </Route>

          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
