
const NotFound = () => {
    return (
        <div className='w-full h-full flex justify-center mt-[15rem] items-center flex-col gap-2 text-black'>
            <h1>404 - Not Found</h1>
            <h5>Sorry, the page you are looking for does not exist.</h5>
        </div>
    );
}

export default NotFound