import { Navigate, Outlet, useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import useUserStore from "../app/user";

export default function AuthLayout() {
  const { user } = useUserStore();

  const location = useLocation();

  return user?.username ? (
    <div className="w-full h-screen flex flex-col">
      <Navbar />

      <Outlet />
    </div>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}
