import { useContext } from "react";

import { OnlineContext } from "../contexts/OnlineContext";

const useOnline = () => {
  const context = useContext(OnlineContext);

  if (!context) throw new Error("Auth context must be use inside AuthProvider");

  return context;
};

export default useOnline;
