import React from 'react';
import BarcodeScanner from '../components/ScannerWeb';

const CopyLotLableQRCode = () => {
  const handleData = async (id) => {};

  return (
    <div className='mt-[10rem] w-full flex flex-col gap-4 justify-center items-center'>
      <BarcodeScanner handleData={handleData} placeholder={'Scan or Manual Input Lot ID'} />
    </div>
  );
};

export default CopyLotLableQRCode;
