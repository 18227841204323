import { useNavigate } from 'react-router-dom';
import useUserStore from '../app/user';

// eslint-disable-next-line react/prop-types
function LogoutAskModal({ setShowModal }) {
  const navigate = useNavigate();
  const { clearUser } = useUserStore();

  const handleCloseModal = () => {
    setShowModal((prev) => !prev);
  };

  const handleLogout = () => {
    // Implement your logout logic here
    console.log('Logging out...');
    clearUser();
    navigate('/login');
    handleCloseModal();
  };

  return (
    <div>
      <div className='fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50'>
        <div className='bg-white rounded-lg p-6'>
          <h3 className='text-lg font-bold mb-4'>Are you sure you want to log out?</h3>
          <div className='flex justify-end'>
            <button onClick={handleCloseModal} className='mr-4 bg-gray-200 text-gray-700 px-4 py-2 rounded'>
              Cancel
            </button>
            <button onClick={handleLogout} className='bg-red-500 text-white px-4 py-2 rounded'>
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogoutAskModal;
