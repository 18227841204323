import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

axios.interceptors.request.use(function (config) {
  const storage = window.localStorage.getItem("print-label");
  const auth = storage ? JSON.parse(storage) : null;
  const user = auth ? auth.state.user : null;

  if (user?.username) config.auth = user;

  config.baseURL = SERVER_URL;

  return config;
});

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.data?.code === "jwt_error") {
      window.localStorage.setItem("print-label", JSON.stringify({ user: null }));
      // window.localStorage.setItem("currentUser", JSON.stringify(null));
    }
    return Promise.reject(error);
  }
);

export default axios;
