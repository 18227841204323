import axios from './axios';

export const getCustomers = async () => {
  const res = await axios.get('/CustomerLST');

  return res.data.value;
};

export const getItems = async () => {
  const res = await axios.get('/ItemLST');

  return res.data.value;
};

export const getItemsTranslate = async () => {
  const res = await axios.get('/ItemBarCode');

  return res.data.value;
};

export const getSanitary = async () => {
  const res = await axios.get('/ItemLedgerEntry');

  return res.data.value;
};
